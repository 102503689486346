import { ROOT_DOMAIN } from '../globals'

export const fbDescription = ' Start a Landslide to crush Trump and the GOP. Track your impact. Multiply your power. '
export const twitterDescription = ' Start a Landslide to crush Trump and the GOP. Track your impact. Multiply your power. '

export const defaultTitle = 'Landslide 2020'
export const defaultDescription = fbDescription
export const defaultOGURL = 'https://landslide2020.org'
export const defaultOGImage = ROOT_DOMAIN + '/static/open-graph-2.jpg'
export const defaultTwitterImage = ROOT_DOMAIN + '/static/open-graph-2.jpg'
